<template>
    <div class="px-2"
        style="-webkit-appearance: none;-moz-appearance: none;appearance: none;opacity: 1"
        v-bind:disabled="locked"
        v-bind:class="statusClass">

      <div class="float-left">
        <span class="outcome-name-text" v-text="outcome_name"></span>
      </div>

      <div class="float-right">
        <span v-if="!locked" class="odd-direction" v-bind:class="directionClass" style="margin-right: 5px;"></span>
        <span v-if="!locked" v-bind:id="id" v-bind:oddValue="odd" class="odd-value">
          <span v-if="locked">-</span>
          <span v-else>{{ odd }}</span>
        </span>
      </div>

      <span v-if="locked" style="height: 1.5em;">
        <LockIcon :size="iconSize" fill-color="black"></LockIcon>
      </span>

    </div>

</template>

<script>
const LockIcon= () => import(/* webpackChunkName: "material-icons" */  'vue-material-design-icons/Lock.vue');

export default {
  name: "Odd",
  props: {
    show_direction: {
      required: false,
      default: true
    },
    previous_odds: {
      required: true
    },
    odds: {
      required: true
    },
    odd_id: {
      required: true
    },
    outcome_name: {
      required: true
    },
    tournament_id: {
      required: true
    },
    market_id: {
      required: true
    },
    outcome_id: {
      required: true
    },
    specifier: {
      required: true
    },
    sport_id: {
      required: true
    },
    market_name: {
      required: true
    },
    tournament_name: {
      required: true
    },
    category_name: {
      required: true
    },
    pick: {
      required: true
    },
  },
  methods: {
    getOddDirectionClass: function () {

      if(parseFloat(this.odd) >= parseFloat(this.previousOdds) ) {

        return 'arrow green up';

      }

      return 'arrow red down';

    },
    getStatusClass: function (){

      this.statusClass = this.locked ? 'lock' : '';

    }
  },
  mounted: function () {

    this.odd = this.odds;
    this.previousOdds = this.previous_odds;

    this.locked = false; //parseInt(this.oddStatus) > 0 || parseInt(this.oddActive) === 0;

    this.getStatusClass();

    this.id = this.getOddID(this.tournament_id,this.market_id,this.outcome_id,this.specifier,'outright');

    this.directionClass = this.getOddDirectionClass();

  },
  data: function (){

    return {
      iconSize: 20,
      id: '',
      odd: '',
      directionClass: '',
      oddStatus: '',
      oddActive:'',
      previousOdds:'',
      producerStatus: '',
      locked: false,
      statusClass:'',
    }
  },
  components: {
    LockIcon
  }
}
</script>